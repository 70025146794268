<template>
    <el-dialog title="编辑" :visible="visible" width="60%" @close="close" :close-on-click-modal="false">
        <el-row>
            <el-form ref="form" :model="form" label-width="120px" style="max-height: 50vh;overflow: auto;padding: 20px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专题" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-select v-model="form.topic" placeholder="请选择" style="width:100%">
                                <el-option label="健康膳食" value="健康膳食"></el-option>
                                <el-option label="运动" value="运动"></el-option>
                                <el-option label="重慢性疾病" value="重慢性疾病"></el-option>
                                <el-option label="心脑血管疾病预防与控制" value="心脑血管疾病预防与控制"></el-option>
                                <el-option label="急救方法" value="急救方法"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属单位：" prop="company" :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <cascaderCompany v-model="form.company"></cascaderCompany>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="标题" prop="title"
                            :rules="{ required: true, message: '不能为空', trigger: 'blur' }">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="描述" prop="summary">
                            <el-input v-model="form.summary" title="添加别名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="正文">
                            <el-input v-model="form.content" type="textarea" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="图片" prop="image">
                            <upload-image2 v-model="form.image" title="上传图片" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="视频" prop="video">
                            <upload-image2 v-model="form.video" title="上传" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否置顶">
                            <el-switch v-model="form.isTop"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否发布">
                            <el-switch v-model="form.isPublic"></el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button style="float:left" v-if="form.id" @click="handleDelete" type="danger" :loading="loading"
                :disabled="loading">删 除</el-button>
            <el-button @click="close">取 消</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">提 交</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        }
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async handleDelete() {

        },
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(() => { return false });
            if (!valid) return
            try {
                const resp = await this.$http.post('/company/jkxj/data', this.form)
                if (resp.data.status == 'success') {
                    this.$message[resp.data.status](resp.data.msg)
                    this.$emit('close')
                    this.$emit('update')
                } else {
                    this.$alert(resp.data.msg, '出错了', { type: 'error' })
                }
            } catch (error) {
                this.$alert(error.message, '出错了', { type: 'error' })
            }
        },
        close() {
            this.$emit("close")
        },
    },
    mounted() {

    }
};
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
